import Login from "../screens/auth/login/login";
import HomeScreen from "../screens/home/home";
const Test: React.FC = () => <div>hello world</div>;
const Test1: React.FC = () => <div>hello world from test 2</div>;
const Test3: React.FC = () => <div>hello world 23242443</div>;
const Test4: React.FC = () => <div>hello world from test 2</div>;

interface RouteData {
  path: string;
  Component: React.FC; 
}

export const publicRoutesData: RouteData[] = [
  {
    path: "/",
    Component: Login,

  }
];

export const privateRoutesData: RouteData[] = [
  {
    path: "/home",
    Component: HomeScreen,
  }
];
