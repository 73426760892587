import { NavBar, WeatherCard, WetherStatsCard, Graph } from "./components";
import { format, addDays } from "date-fns";
import "./home.css";
import useHomeViewModal from "./homeViewModal";
export default function HomeScreen(): JSX.Element {
  const array = [1, 2, 3, 4];
  const {
    weatherData,
    currentDate,
    handlePrevDate,
    handleNextDate,
    getLastUpdateTime,
    getCurrentDateAndTime,
    stats,
    userData,
    graphData,
    isLoading = true,
  } = useHomeViewModal();
  return (
    <div style={{ padding: 10 }}>
      <NavBar />
      <div>
        <h2>Dashboard</h2>
        <h4>Welcom {userData?.name} Ji</h4>
      </div>
      <div>
        <h2>Weather Report</h2>
      </div>
      <div className="weatherCardContainer">
        <WeatherCard
          weatherData={weatherData}
          getLastUpdateTime={getLastUpdateTime}
          getCurrentDateAndTime={getCurrentDateAndTime}
        />
        <div className="weatherStats">
          {Object.keys(stats).map(
            (element: any, index: number): JSX.Element => {
              return (
                <WetherStatsCard
                  key={index}
                  title={element}
                  data={stats[element]}
                  icon="abc.png"
                />
              );
            }
          )}
        </div>
      </div>
      <div className="RepordHeader">
        <h2>
          {" "}
          Agristick Data Report
          <div className="dateDisplay">
            <button onClick={handlePrevDate}>&lt;</button>
            <span>{format(currentDate, "dd/MM/yyyy")}</span>
            <span> -</span>
            <span>{format(addDays(currentDate, 6), "dd/MM/yyyy")}</span>
            <button onClick={handleNextDate}>&gt;</button>
          </div>
        </h2>

        <div className="graphs">
          <Graph
            title={"Soil Mostiure"}
            data={graphData}
            x={"day"}
            loading={isLoading}
            y={"averageSoilMoisture"}
          />
          <Graph
            title={"Leaf Wetness"}
            data={graphData}
            x={"day"}
            loading={isLoading}
            y={"averageLeafWetness"}
          />
        </div>
      </div>
    </div>
  );
}
