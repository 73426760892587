import { createContext,useState } from "react";
import {  userListProviderProps , userProps } from "../../types/context.type/userList.type";
export const UserContext = createContext <userProps>({userData:{}, agristickId:""});

export const UserProvider = (props: userListProviderProps) => {
  const { children } = props;
  const data= localStorage.getItem("user") 
  const agristick = localStorage.getItem('agristick')
  const userData = data && JSON.parse(data)
  const agristickId =  agristick && JSON.parse(agristick)._id
  const value: userProps = { userData ,agristickId };
  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};