import { createContext,useState } from "react";
import { authContextType, userListProviderProps } from "../../types/context.type/userList.type";
export const AuthContext = createContext<authContextType>({
 isAuthorise:false,
 setIsAuthorise:()=>{},
  loading: false,
  setLoading: () => {},
});

export const AuthProvider = (props: userListProviderProps) => {
  const { children } = props;
  const authorise = localStorage.getItem("jwtToken") ? true  : false
  const [isAuthorise , setIsAuthorise] = useState(authorise)
  const [loading, setLoading] = useState(false);
  const value = {
    isAuthorise,
    setIsAuthorise,
    loading,
    setLoading,
  };
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};