import {
  Sidebar as ProSidebar,
  Menu,
  MenuItem,
  useProSidebar,
} from "react-pro-sidebar";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { NavIcons } from "../../res/assets";
import NavItem from "./comp";
import "./index.css";
import Tools from "../Tools";
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
const mainStyles = {
  backgroundColor: "white",
  height: Tools.deviceHeight,
  borderTopRightRadius: 50,
  borderBottomRightRadius: "50px",
};
const menuStyles = {
  padding: "20px 5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
function Sidebar() {
  const navigate = useNavigate();
  const { setIsAuthorise } = useContext(AuthContext);
  const { toggleSidebar, broken } = useProSidebar();
  const logout = () => {
    localStorage.clear();
    setIsAuthorise(false);
    navigate("/");
  };
  return (
    <div className="sidebarContainer">
      <div style={{ position: "fixed", zIndex: 2 }}>
        <ProSidebar
          style={{
            ...mainStyles,
          }}
          breakPoint="lg"
          backgroundColor="#29451D"
        >
          <Menu style={menuStyles}>
            <NavIcons.Logo />
            <div className="menuItemContainer">
              <MenuItem style={{ backgroundColor: "#29451D" }}>
                <NavItem
                  redirect="/home"
                  ActiveIcon={NavIcons.DasFill}
                  InActiveIcon={NavIcons.DasEmpty}
                  title="Dashboard"
                />
              </MenuItem>
              <MenuItem style={{ backgroundColor: "#29451D" }}>
                <NavItem
                  redirect="/"
                  ActiveIcon={NavIcons.DasFill}
                  InActiveIcon={NavIcons.DasEmpty}
                  title="Users"
                />
              </MenuItem>
              <MenuItem style={{ backgroundColor: "#29451D" }}>
                <NavItem
                  redirect="/"
                  ActiveIcon={NavIcons.DasFill}
                  InActiveIcon={NavIcons.DasEmpty}
                  title="Setting"
                />
              </MenuItem>
              {/* <MenuItem style={{ backgroundColor: "#29451D" }}>
                <button className="logoutButton" onClick={logout}>
                  Sign-Out
                </button>
              </MenuItem> */}
            </div>
          </Menu>
          <button className="logoutButton" onClick={logout}>
            Sign-Out
          </button>
        </ProSidebar>
      </div>
      <main
        style={{
          position: "absolute",
          right: 0,
          zIndex: 1,
          width: broken ? Tools.deviceWidth : Tools.deviceWidth - 260,
          ...mainStyles,
        }}
      >
        {broken && (
          <div className="hamburgerIcon">
            <button className="sb-button" onClick={() => toggleSidebar()}>
              <NavIcons.HamburgerIcon />
            </button>
            <div className="topLogo">
              <NavIcons.Logo />
            </div>
          </div>
        )}
        <div className="screenPanel">
          <Outlet context={broken} />
        </div>
      </main>
    </div>
  );
}

export default Sidebar;
