import { useContext, useEffect, useMemo, useState, useCallback } from "react";
import { WeatherRepository } from "../../respository/Weather";
import { HomeRepository } from "../../respository/home";
import { addDays, subDays, format, startOfWeek } from "date-fns";
import { UserContext } from "../../context/userContext";

export default function useHomeViewModal(): any {
  const weatherRepository = useMemo(() => new WeatherRepository(), []);
  const homeRepository = useMemo(() => new HomeRepository(), []);
  const [currentDate, setCurrentDate] = useState(getStartOfWeek()); // Initialize with the start of the current week
  const [weatherData, setWeatherData] = useState({});
  const [graphData, setGraphData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { userData, agristickId } = useContext(UserContext);
  const [stats, setStats] = useState({});
  const getWeatherData = useCallback(async () => {
    try {
      const data = await weatherRepository.getWeatherData();
      setWeatherData({ ...data });
      setStats({
        humidity: data.current?.humidity ?? 0,
        wind: `${data.current?.wind_kph ?? 0} k/h`,
        "heat index": `${data.current?.heatindex_c ?? 0} C`,
        uv: data.current?.uv ?? 0,
      });
    } catch (err: any) {
      console.log(err.message);
    }
  }, [weatherRepository]);

  function getLastUpdateTime(timestamp: string): string {
    const dateObj = new Date(timestamp);
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
    return formattedTime;
  }

  function getCurrentDateAndTime(): any {
    const currentTimestamp = new Date();
    const formattedDate = currentTimestamp.toLocaleString("en-US", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    const formattedTime = currentTimestamp.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return {
      date: formattedDate,
      time: formattedTime,
    };
  }

  function getDayNameFromDate(date: any) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return daysOfWeek[date.getDay()];
  }

  const getAgristickFeildData = async (current: Date) => {
    try {
      setIsLoading(true);
      const response = await homeRepository.getAgriStickFeildData(
        "651440f036584038624f15c0",
        current
      );
      const dataWithDayNames = response.averageFieldData.map(
        (data: any, index: number) => ({
          ...data,
          day: getDayNameFromDate(new Date(data.createdAt)),
          averageLeafWetness: parseFloat(data.averageLeafWetness), // Convert to float
          averageSoilMoisture: parseFloat(data.averageSoilMoisture),
        })
      );

      setGraphData(dataWithDayNames);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrevDate = () => {
    setCurrentDate((prevDate: Date) => subDays(startOfWeek(prevDate), 6)); // Subtract 7 days from the start of the current week
  };

  const handleNextDate = () => {
    setCurrentDate((prevDate: Date) => addDays(startOfWeek(prevDate), 8)); // Add 7 days to the start of the current week
  };

  useEffect(() => {
    getWeatherData();
    getAgristickFeildData(currentDate);
  }, [currentDate]);

  return {
    weatherData,
    getLastUpdateTime,
    getCurrentDateAndTime,
    graphData,
    currentDate,
    handlePrevDate,
    stats,
    handleNextDate,
    userData,
    isLoading,
  };
}

// Function to get the start of the current week (Monday)
function getStartOfWeek(): Date {
  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay();
  const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Sunday is treated as the first day of the week
  return subDays(currentDate, daysToSubtract);
}
