import React, { useState, useContext , useMemo, useEffect } from "react";
import { FirebaseService } from "../../../services/firebase";
import { useNavigate } from "react-router-dom";
import { AuthRepository } from "../../../respository/auth";
import { AuthContext } from "../../../context/authContext";
const useLoginViewModel = (): any => {
  const { isAuthorise , setIsAuthorise } = useContext(AuthContext);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [OTP, setOTP] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const navigate = useNavigate();
  const firebaseService = useMemo(() => new FirebaseService(), []);
  const authRepository = useMemo(() => new AuthRepository(), []);
  const getOTP = async () => {
    try {
      if (phoneNumber.length === 10) {
        const isOTPsent = await firebaseService.getOTP(phoneNumber);
        setShowOTP(isOTPsent);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const verifyOTP = async () => {
    try {
      if (OTP.length !== 0) {
        const credential = await firebaseService.verifyOTP(OTP);
        if (credential) {
          const response = await authRepository.login(phoneNumber);
          console.log(response);
          if(response.token !== undefined){
            localStorage.setItem("jwtToken" , response.token);
            localStorage.setItem("user" , JSON.stringify(response?.user))
            localStorage.setItem("agristick" , JSON.stringify(response?.agriStick[0]))
            setIsAuthorise(true)
            navigate("/home");
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
     if(isAuthorise){
       navigate("/home");
     }
  }, []);
  return {
    setPhoneNumber,
    phoneNumber,
    getOTP,
    OTP,
    setOTP,
    verifyOTP,
    showOTP,
  };
};
export default useLoginViewModel;
