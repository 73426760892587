import { AppUrl } from "../../utils/appUrl";
import { ApiService } from "../../data/network/apiService";
export class AuthRepository {
  apiserivce: ApiService;
  constructor() {
    this.apiserivce = new ApiService();
  }
  async login(phoneNumber: string) {
    const url = `${AppUrl.authEndpoint}/checkuser/${phoneNumber}?isDashboardLogin=true`;
    console.log(url);
    return await this.apiserivce.getGetApiResponse(url);
  }
}
