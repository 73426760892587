import { AppUrl } from '../../utils/appUrl';
import { ApiService } from './../../data/network/apiService';

export class WeatherRepository extends AppUrl{
    apiService = new ApiService();

    async getWeatherData() {
            const url = AppUrl.weatherApi
            return await this.apiService.getGetApiResponse(url);
    }
     
}