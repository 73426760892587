import CombinedProviders from "./context";
import HomeScreen from "./screens/home/home";
import RouteNavigator from "./routes";
function App() {
  const dev_type = process.env.REACT_APP_DEV_TYPE;
  console.log(dev_type);
  return (
    <div
      style={{
        backgroundColor: "white",
      }}
    >
      <CombinedProviders>
        <RouteNavigator />
      </CombinedProviders>
    </div>
  );
}
export default App;
