import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { publicRoutesData, privateRoutesData } from "./routeData";
import Component from "../components";
import { ProSidebarProvider } from "react-pro-sidebar";
import PrivateRoutes from "./PrivateRoute";

const RouteNavigator = (): JSX.Element => {
  return (
    <ProSidebarProvider>
      <Routes>
        {publicRoutesData.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}

        <Route element={<PrivateRoutes />}>
          <Route element={<Component.SideBar />}>
            {privateRoutesData.map(({ path, Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </Route>
        
      </Routes>
    </ProSidebarProvider>
  );
};

export default RouteNavigator;
