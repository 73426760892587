import { AppUrl } from '../../utils/appUrl';
import { ApiService } from './../../data/network/apiService';

export class HomeRepository{
    apiService = new ApiService();

    async getUsers(pageNumber:number) {
        const url = `${AppUrl.userEndPoint}?page=${pageNumber}`;
        return await this.apiService.getGetApiResponse(url);
    }
    async getAgriStickFeildData(agristick: string , date?:Date){
        const url = `${AppUrl.BASE_URL}/agristick/averageFeildData/${agristick}?startDate=${date}`;
        return await this.apiService.getGetApiResponse(url);
    }

}