import { WeatherCardProps, weatherStatsProps, graphProps } from "./propsType";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Component from "../../../components";
import { UserContext } from "../../../context/userContext";
import { useContext } from "react";
import { TiWeatherSunny } from "react-icons/ti";
import "./index.css";
import { IoNotificationsSharp } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { Audio } from "react-loader-spinner";

export const NavBar = (): JSX.Element => {
  const { userData, agristickId } = useContext(UserContext);

  return (
    <div className="navContainer">
      <div>
        <IoNotificationsSharp size="32px" color="black" />
      </div>
      <div className="profileContainer">
        <div className="profileData">
          {/* {userData?.profileImage ? (
            <img src={userData?.profileImage ?? ""} alt="not available" />
          ) : ( */}
          <FaUserCircle size="54px" color="lightGray" />
          {/* )} */}

          <div>
            <h3 style={{ textAlign: "center" }}> {userData?.name} </h3>
            <span> Stick ID {agristickId}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export const WeatherCard = ({
  weatherData,
  getLastUpdateTime,
  getCurrentDateAndTime,
}: WeatherCardProps): JSX.Element => {
  const { current } = weatherData;
  const { date, time } = getCurrentDateAndTime();
  const lastUpdatedAt = getLastUpdateTime(current?.last_updated);
  return (
    <div className="weatherCard">
      <div className="DateAndTimeContainer">
        <span> {date} </span>
        <span> {time} </span>
      </div>
      <div className="weatherIcon">
        <img src={current?.condition?.icon} />
        <div className="weatherData">
          <span> {parseInt(current?.temp_c)}^C</span>
          <span className="statusTag"> {current?.condition?.text}</span>
        </div>
      </div>
      <div className="updateStatusContainer">
        <span> Last Update {lastUpdatedAt}</span>
      </div>
    </div>
  );
};

export const WetherStatsCard = ({
  icon,
  data = "35 %",
  title = "Humidity",
}: weatherStatsProps): JSX.Element => {
  return (
    <div className="weatherStatsCard">
      <div>
        <TiWeatherSunny style={{ color: "blue", fontSize: 30 }} />
        {/* <img src={icon}/>            */}
      </div>
      <div className="weatherData">
        <span className="data">{data}</span>
        <span className="statsTitle">{title}</span>
      </div>
    </div>
  );
};

export const Graph = ({
  title = "Graph Title",
  data,
  x,
  y,
  loading,
}: graphProps) => {
  return (
    <div className="graphContainer">
      <div className="graphHeader">
        <h3>{title}</h3>
      </div>

      {/* Conditional rendering for loading spinner */}
      <div
        style={{
          minWidth: "300px",
          maxWidth: "90vw",
          width: window.innerWidth * 0.3, // Avoid Component.deviceWidth, use window
          maxHeight: 300,
          height: window.innerHeight * 0.3,
        }}
      >
        {loading ? (
          // Display loading spinner or placeholder text
          <div className="loadingContainer">
            <Audio
              height="100"
              width="100"
              color="#28a745"
              ariaLabel="loading"
            />
          </div>
        ) : (
          // If data is loaded and not empty, render the chart
          <ResponsiveContainer>
            <AreaChart
              data={data}
              margin={{
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={x} />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey={y}
                stroke="#8884d8"
                fill="#8884d8"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};
