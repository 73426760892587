import React from "react";
import useLoginViewModel from "./loginViewModel";
import { LogoBanner, NavIcons } from "../../../res/assets";
import { MuiOtpInput } from "mui-one-time-password-input";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import "./login.css";
import Component from "../../../components";

const Login = (): JSX.Element => {
  const {
    phoneNumber,
    setPhoneNumber,
    getOTP,
    OTP,
    setOTP,
    verifyOTP,
    showOTP,
  }: any = useLoginViewModel();

  return (
    <div className="LoginMainContainer">
      {Component.deviceWidth > 900 && (
        <div style={{ width: Component.deviceWidth * .2, display: Component.deviceWidth <= 9000 ? "block" : "none" }}>
          <LogoBanner />
        </div>
      )}
      <div className="fonrmContainer" style={{
         width: Component.deviceWidth < 900 ?
          Component.deviceWidth : Component.deviceWidth * .6,
           height: Component.deviceHeight }} >
        <div className="BannerLogo">
          <NavIcons.LogoBig />
        </div>
        {!showOTP && (
          <div className="phoneNumberContainer">
            <h1>Login</h1>
            <p>Enter your 10-digit phone number to login</p>
            <TextField
              type="number"
              autoFocus
              error={phoneNumber.length !== 0 && phoneNumber.length !== 10}
              style={{ width: Component.deviceWidth < 900 ?
                Component.deviceWidth*.7 : Component.deviceWidth * .2,  }}
              label="Phone Number"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <div id="recaptcha-container"></div>
            <button className="LoginButton" onClick={getOTP}>
              Send OTP
            </button>
          </div>
        )}
        {showOTP && (
          <div className="phoneNumberContainer">
            <h1>Verify OTP</h1>
            <p> Enter the 4 digit verification code which has been
              sent to your Mobile Phone.</p>
            <MuiOtpInput
              width={
                Component.deviceWidth < 900 ?
                Component.deviceWidth*.8 : Component.deviceWidth * .2
              }
              style={{margin:0}}
              gap={3}
              value={OTP}
              length={6}
              onChange={(e) => setOTP(e)}
              onComplete={verifyOTP}
            />
            <button className="LoginButton" onClick={verifyOTP}>
              Login
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
