import React from "react";
import { NavLink, useLocation } from "react-router-dom"; // Import 'useLocation' to determine isActive
import Fonts from "../../res/fonts";
import "./index.css";

type NavProps = {
  isActive: boolean; // Change type to boolean
  ActiveIcon: React.ComponentType; // Change type to React.ComponentType
  InActiveIcon: React.ComponentType; // Change type to React.ComponentType
  title: string;
};

const Nav = ({ isActive, ActiveIcon, InActiveIcon, title }: NavProps) => {
  return (
    <div className="nav-item">
      {/* {isActive ? <ActiveIcon /> : <InActiveIcon />}
      <span className="spacer-left-10" /> */}
      <Fonts.LightFont pointer={true} fontSize={16} lineHeight={32}>
        {title}
      </Fonts.LightFont>
    </div>
  );
};

type NavItemProps = {
  redirect: string;
  ActiveIcon: React.ComponentType;
  InActiveIcon: React.ComponentType;
  title: string;
};

export default function NavItem({ redirect, ActiveIcon, InActiveIcon, title }: NavItemProps) {
  const location = useLocation(); // Get the current location to determine isActive
  const isActive = location.pathname === redirect;

  return (
    <NavLink to={redirect} className={isActive ? "active" : "inactive"}>
      <Nav
        isActive={isActive}
        ActiveIcon={ActiveIcon}
        InActiveIcon={InActiveIcon}
        title={title}
      />
    </NavLink>
  );
}
